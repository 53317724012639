/* prettier-ignore */
/*-----------------------------------------------
|   Navigation 
------------------------------------------------*/
/*-----------------------------------------------
|   Navbar Brand 
------------------------------------------------*/
/*# sourceMappingURL=user.css.map */

.nav-item:hover {
    background-color: #DBB121;
}


.nav-link:hover {
    color: white;
}

.nav-item {
    font-size: 18px;
}

.text-yellow {
    color: #DBB121;
}

p {
    font-size: 16px;
    color: white;
    font-family: "Lato",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

@media only screen and (max-width: 600px) {
    .navbar-collapse {
      background-color: black;
    }
}
